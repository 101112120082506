<template>
  <div>
    <defaultFieldTypes
      field="c_address"
      :fieldAttributes="result.fields['c_address']"
      v-on="$listeners"
      :templateContent="result"
    :value="result.valueLine.c_address"></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["result"],
  created() {
    this.setActiveProbeInfo(this.result.valueLine.n_systemId);
  },
  watch: {
    "result.valueLine.n_systemId": function (newValue) {
      console.log("systemId changed");
      this.setActiveProbeInfo(newValue);
    },
  },
  computed: {
    systeminfo() {
      return this.result.fields.n_systemId.fieldAttrInput.systeminfo;
    },
  },
  methods: {
    setActiveProbeInfo(value) {
      let systeminfoObject = JSON.parse(this.systeminfo);
      if (typeof systeminfoObject[value] == "undefined") {
        systeminfoObject[value] = {};
      }
      let ips = Object.keys(systeminfoObject[value]);

      this.$set(this.result.fields.c_address, "associatedOption", ips);
      this.$store.commit("dialogResultChanged", this.result);

      //this.activeProbeIPInfo = systeminfoObject[value];

      // this.setActiveProbeProtocol(this.result.valueLine.c_address);
    },
  },
};
</script>